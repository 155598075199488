import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
} from "react-bootstrap";
import { fetchAutoemailData, fetchPickTemplateListing, seletedTemplateData } from "../../../redux/actions/campaignAction"
import * as Icon from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import "./campaign.css";
import MagicStick from "../../../assets/asset-upload/Vector.png";
import Pagination from "./CampaignPagination";
import { useNavigate } from "react-router-dom";
import ThumbImg from "../../../assets/asset-upload/no-thumb.jpg";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { DebounceInput } from "react-debounce-input";
import Loading from "../../Loading";

function PickTemplate(props) {
  const authToken = props.authToken;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tabData, setTabData] = useState("all");
  const [searchField, setSearchField] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  let pageNum = useSelector((state) => state.campaign.currPage);
  const pick_up_listing = useSelector((state) => state.campaign.pick_up_listing?.records);
  const total_data = useSelector((state) => state.campaign?.pick_up_listing?.count);
  const isLoading = useSelector((state) => state.campaign?.loading);
  
  const userType = localStorage.getItem("userType")
    ? localStorage.getItem("userType").slice(1, -1)
    : "";
  
  // const log = () => {
  //   if (editorRef.current) {
  //     console.log(editorRef.current.getContent());
  //   }
  // };
  
  const handleProceed = async (pick) => {
    await dispatch(seletedTemplateData(pick));
    await dispatch(fetchAutoemailData(authToken, pick?.content_id, 1));
    navigate(`/campaigns/${props.subPageName === "run_template" ? "run_campaign":"push_campaign"}`)
  }

  useEffect(() => {
    dispatch(fetchPickTemplateListing(authToken, searchField, tabData, pageNum, props.subPageName, userType))
  }, [tabData])
  
  
  return (
    <div className="auto-email">
      {isLoading && <Loading />}
      <div className="new-asset">
        <Card className="mx-2 border border-0">
        <Row className="pick-container-screen">  
            <Col className="pick-template-heading d-flex justify-content-between align-item-center col-lg-12">
                <div>              
                    <span>Pick a Template</span>
                    <span className="mx-3"><img className="image-magic" src={MagicStick} alt="magic"/></span>
                </div>
                <Col className="col-4 position-relative">
                    <DebounceInput
                        value={searchField}
                        className="search-field form-control pos"
                        placeholder={`Search Template`}
                        minLength={3}
                        debounceTimeout={1500}
                        onChange={(e) => {
                          setSearchField(e.target.value);
                          dispatch(fetchPickTemplateListing(authToken, e.target.value, tabData, pageNum, props.subPageName))
                        }}
                    />
                    { true ? (
                        <div className="search-icon">
                        <Icon.Search
                            // onClick={(e) => {
                            //     searchSubmitHandler(e, searchRef.current.value, pagename);
                            // }}
                        />
                        </div>
                    ) : (
                        <div className="search-icon">
                        <Icon.X
                            // onClick={(e) => {
                            //     searchSubmitHandler(e, null, pagename);
                            // }}
                        />
                        </div>
                    )}
                </Col>  
            </Col>
            <Col className={`pick-fields-card col-md-12`}>
              <nav class="d-flex gap-3 justify-content-left mb-3">
                <span onClick={() => { setTabData("all")}} class={`text-dark cursor-pointer fw-medium py-1 px-1 ${tabData === "all" && "active-nav"}`}>
                  All
                </span>
                <span onClick={() => { setTabData("text")}} class={`text-dark cursor-pointer fw-medium py-1 px-1 ${tabData === "text" && "active-nav"}`}>
                  Text
                </span>
                <span onClick={() => { setTabData("html")}}  class={`text-dark cursor-pointer fw-medium py-1 px-1 ${tabData === "html" && "active-nav"}`}>
                  Html
                </span>              
              </nav>
                <div className="pick-template-row row">
                    {pick_up_listing?.length > 0 && pick_up_listing?.map((pick, p_index) => {
                        return (
                          <Col onClick={() => { setSelectedTemplateId(pick); handleProceed(pick)}} className={`mb-4 col-lg-3 col-md-6 col-sm-6 col-12 cursor-pointer`} key={p_index}>
                                <Card className={`asset-card ${pick?.content_id === selectedTemplateId?.content_id ? "pick-template-active":""}`}>
                                    <Card.Img variant="top" src={pick?.thumb_image || ThumbImg} alt="thumb"/>
                                    <Card.Body>
                                        <div className="card-body-wrapper">
                                            <Card.Title>
                                                <div
                                                    className="solution_types"
                                                    >
                                                    {pick.content_title}
                                                </div>
                                            </Card.Title>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        );
                    })}
                    {pick_up_listing?.length === 0 && (
                      <div className="no-data asset">
                        <span>
                          <Icon.Inbox />
                        </span>
                        <br />
                        No Data
                      </div>
                    )}
              </div>

              {total_data > 20 && <Pagination authToken={props.authToken} searchField={searchField} tabData={tabData} total_page={ total_data } page={pageNum} currPage={props.subPageName} />}
              
              {/* {pick_up_listing?.length > 0 && (
                <Button
                  variant="info"
                  type="submit"
                  className="save-proceed-btn btn-pos mt-lg-0"
                  onClick={handleProceed}
                >
                  Proceed
                  <span>
                    <Icon.ChevronRight />
                  </span>
                </Button>)} */}
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
}

export default PickTemplate;