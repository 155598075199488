import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Form,
  Card,
  Button,
  Modal,
  FormGroup,
  FloatingLabel,
  Dropdown,
} from "react-bootstrap";
import Select from 'react-select';
import { addContactList, fetchContactListData, fetchContentListData, fetchEmailListing, fetchListDataListing, pushEmailCampaign, runTestEmailCampaign } from "../../../redux/actions/campaignAction"
import * as Icon from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import * as types from "../../../constants/ActionTypes";
// import "./emailasset.css";
import storeApis from "../../../api/storeApi";
import { Editor } from '@tinymce/tinymce-react';
// import CropImage from "./CropImage";
import Toast from "../../shared/Toast";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useDispatch } from "react-redux";
import Loading from "../../Loading";
import DatePicker from 'react-datepicker';
//import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import { fetchGreetingList, fetchPartnersAccessList } from "../../../redux/actions/actions";
import moment from "moment";
import ListModal from "./ListModal";
import { DebounceInput } from "react-debounce-input";

function EmailCampaign(props) {
  const authToken = props.authToken;
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const editorRef = useRef(null);
  const hiddenFileInput = useRef(null);
  const [file, setFile] = useState(null);
  const [currentPopupList, setCurrentPopupList] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [campaignFromName, setCampaignFromName] = useState("");
  const [date, setDate] = useState("");
  const [imagefile, setImagefile] = useState(null);
  const [sortValue, setSortValue] = useState({title: "",value: ""});
  const [searchField, setSearchField] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [selectedPartnerCategory, setSelectedPartnerCategory] = useState(null);
  const [templateDescription, setTemplateDescription] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedFundName, setSelectedFundName] = useState(false);
  const [tagName, setTagName] = useState("");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [searchContent, setSearchContent] = useState("");
  const [selectedAttachContent, setSelectedAttachContent] = useState(null);
  const [htmlContent, setHtmlContent] = useState("");
  const [emailBody, setEmailBody] = useState(null);
  const [fromEmail, setFromEmail] = useState(null);
  const [replyEmail, setReplyEmail] = useState(null);
  const [autoEmailContentId, setAutoEmailContentId] = useState(null);
  const [tabData, setTabData] = useState("details");
  const [selectedCtaType, setSelectedCtaType] = useState("none");
  const [selectedHashtag, setSelectedHashtag] = useState([]);
  const [selectedGreeting, setSelectedGreeting] = useState(null);
  const [selectedShowcasePage, setSelectedShowcasePage] = useState(null);
  const [selectedLandingPage, setSelectedLandingPage] = useState(null);
  const [selectedPushList, setSelectedPushList] = useState(null);
  const [pushNotification, setPushNotification] = useState(false);
  const [selectedFromEmail, setSelectedFromEmail] = useState(null);
  const [selectedReplyEmail, setSelectedReplyEmail] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [contentOptions, setContentOptions] = useState(null);
  const [campaignDescription, setCampaignDescription] = useState("");
  const [permissionRem, setPermissionRem] = useState(false);
  const [attachContent, setAttachContent] = useState(false);
  const [testModal, setTestModal] = useState(false);
  const [testEmail, setTestEmail] = useState("");
  const [currentSipChoice, setCurrentSipChoice] = useState("none");
  const [inputValue, setInputValue] = useState('');
  const [tagModal, setTagModal] = useState(false);
  const [listPopup, setListPopup] = useState(false);
  const [buttonPos, setButtonPos] = useState({ left: -1000, top: 0, show: false });
  const inputRef = useRef(null);
  
  const userType = localStorage.getItem("userType")
    ? localStorage.getItem("userType").slice(1, -1)
    : "";
  
  const now = new Date();
  const currentTime = new Date(now.getTime());
  const nowPlus4Hours = new Date(now.getTime() + 4 * 60 * 60 * 1000);
  
  const isToday = date && date.toDateString() === now.toDateString();
  const minSelectableTime = isToday ? nowPlus4Hours : new Date(now.setHours(0, 0, 0, 0));

  const minSelectableDirectTime = isToday ? currentTime : new Date(now.setHours(0, 0, 0, 0));
  const maxSelectableTime = isToday ? new Date(now.setHours(23, 59, 59, 999)) : new Date(now.setHours(23, 59, 59, 999));
  
  const selectedAssetType = useSelector(
    (state) => state.assetUpload.selectedAssetType
  );
  
  const current_template = useSelector(
    (state) => state.campaign.current_template
  );
  
  const autoEmailPath = useSelector(
    (state) => state.assetUpload.autoEmailPath
  );

  const showcaseUrlList = useSelector(
    (state) => state.assetUpload.showcaseUrlList
  );
  
  const autoemailStatus = useSelector(
    (state) => state.assetUpload.autoemailStatus
  );
  
  const autoemail_main_data = useSelector(
    (state) => state.assetUpload.autoemail_main_data
  );
  
  const currentAssetDetails = useSelector(
    (state) => state.assetLibrary?.currentAssetDetails?.length > 0 ? state.assetLibrary?.currentAssetDetails[0] : null
  );
  
  const run_campaign_data = useSelector(
    (state) => state.campaign.run_campaign_data
  );
  
  const greetingList = useSelector((state) => state.assetUpload.greetingList);
  
  const assetLibraryCategoryList = useSelector(
    (state) => state.assetLibrary.categoryList
  );
  
  const individual_template = useSelector(
    (state) => state.campaign.individual_template
  );
  
  const isLoading = useSelector((state) => state.campaign.loading);
  
  const contact_list = useSelector(
    (state) => state.campaign.contact_list
  );
  
  const filtered_contact_list = useSelector(
    (state) => state.campaign.filtered_contact_list
  );
  
  const from_email = useSelector(
    (state) => state.campaign.from_email
  );
  
  const reply_email = useSelector(
    (state) => state.campaign.reply_email
  );
  
  const partnersList = useSelector((state) => state.assetUpload.partnersList);
  
  const personalize_variable = useSelector((state) => state.assetUpload.autoEmailData?.response?.personalize_var);
  
  useEffect(() => {
    if (from_email?.length > 0) {
      let data1 = from_email?.map((item, i_ind) => {
        let a = item
        item = {};
        item.value = a;
        item.key = a
        return item;
      })
      setFromEmail(data1);
    }
    if (reply_email?.length > 0) {
      let data2 = reply_email?.map((item, i_ind) => {
        let a = item
        item = {};
        item.value = a;
        item.key = a
        return item;
      })
      setReplyEmail(data2);
    }
  }, [from_email, reply_email])
  
  const getEmailOptionLabel = (option) => option.key;
  const getContactOptionLabel = (option) => option.contact_list_name;
  
  useEffect(() => {
    dispatch(fetchGreetingList(authToken));
    dispatch(fetchContactListData(authToken, props.subPageName !== "run_campaign" ? 1:0));
    dispatch(fetchContentListData(authToken, searchContent));
    dispatch(fetchPartnersAccessList(authToken));
    setHtmlContent(current_template?.message);
    dispatch(fetchEmailListing(authToken, "from"));
    dispatch(fetchEmailListing(authToken, "reply"));
    loadOptions("");
  }, []);
  
  useEffect(() => {
    if (individual_template) {
      const filteredContactObjects = contact_list?.filter((obj) => {
        return individual_template?.list_id?.includes(obj?.list_id?.toString())
      });
      setAutoEmailContentId(individual_template?.mail_id);
      setCampaignName(individual_template?.camp_name || "");
      setTemplateDescription(individual_template?.subject || "");
      setCampaignFromName(individual_template?.from_name || "")
      setHtmlContent(individual_template?.message || "");
      setEmailBody(individual_template?.message || "");
      setPermissionRem(individual_template?.pemission_reminder === 1 ? true : false);
      setCampaignDescription(individual_template.reminder_text || "");
      setSelectedContact(filteredContactObjects);
      // setDate(individual_template.scheduled_email || "");
      // const filteredContactObjects = assetLibraryHashtagList?.filter(obj => individual_template?.hashtag?.includes(obj.id));
      const filteredCategoryObjects = assetLibraryCategoryList?.filter(obj => autoemail_main_data?.category?.includes(obj.id));
      
      // setSelectedHashtag(filteredHashtagObjects);
      setSelectedCategory(filteredCategoryObjects);

      // const filteredLandingPage = landingpageUrlList.find(obj => obj.publish_page_id === autoemail_main_data?.landing_page);
      const filteredAttachedContentData = contentOptions?.find(obj => obj.id === individual_template?.attached_content);
      setSelectedGreeting(individual_template?.greeting_id);
      setSelectedAttachContent(filteredAttachedContentData);
      setAttachContent(individual_template.attachment_option === 1 ? true : false)
    }
  }, [individual_template])
  
  // useEffect(() => {
  //   if (Object.keys(run_campaign_data).length > 0) {
  //     const filteredContactObjects = contact_list?.filter(obj => autoemail_main_data?.list_id?.includes(obj.list_id));
  //     const filteredReplyEmailObjects = replyEmail?.find(obj => run_campaign_data?.reply_email == obj.value);
  //     const filteredFromEmailObjects = fromEmail?.find(obj => run_campaign_data?.from_mail == obj.value);
  //     setCampaignName(run_campaign_data?.camp_name || "");
  //     setCampaignFromName(run_campaign_data.from_name);
  //     setSelectedReplyEmail(filteredReplyEmailObjects);
  //     setSelectedFromEmail(filteredFromEmailObjects);
  //     setTemplateDescription(run_campaign_data?.subject || "");
  //     setCampaignFromName(run_campaign_data?.from_name || "")
  //     setHtmlContent(run_campaign_data?.message?.replace(`contenteditable="true"`,`contenteditable="false"`) || "");
  //     setEmailBody(run_campaign_data?.message?.replace(`contenteditable="true"`,`contenteditable="false"`) || "");
  //     setPermissionRem(run_campaign_data?.pemission_reminder == 1 ? true : false);
  //     setCampaignDescription(run_campaign_data.reminder_text || "");
  //     setSelectedContact(filteredContactObjects);
  //   }    
  // }, [run_campaign_data])
  
  useEffect(() => {
    if (editorRef.current) {
      setEmailBody(editorRef.current.getContent());
    }
  }, [editorRef.current])
  
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  
  const handleScroll = () => {
    setIsButtonVisible(false);
  };
  
  // const loadOptions = async (inputValue, callback) => {
  //   dispatch(fetchContentListData(authToken, inputValue, callback));      
  // }
  
  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    loadOptions(newValue);
  };
  
  const handleDateChange = (date) => {
    // Check if the selected date is today
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const selected = new Date(date);
    selected.setHours(0, 0, 0, 0);

    if (selected.getTime() === today.getTime()) {
      if (date < nowPlus4Hours) {
        // If the selected date is today and the time is before 4 hours from now,
        // set the time to 4 hours from now
        date.setHours(nowPlus4Hours.getHours(), nowPlus4Hours.getMinutes());
      }
    }
    setDate(date);
  };
  
  const handleDirectDateChange = (date) => {
    // Check if the selected date is today
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const selected = new Date(date);
    selected.setHours(0, 0, 0, 0);

    if (selected.getTime() === today.getTime()) {
      if (date < currentTime) {
        // If the selected date is today and the time is before 4 hours from now,
        // set the time to 4 hours from now
        date.setHours(currentTime.getHours(), currentTime.getMinutes());
      }
    }
    setDate(date);
  };
  
  const handleModalValue = (value) => {
    setCurrentPopupList(value)
  }
 
  const loadOptions = async (inputValue) => {
        try {
            const response = await storeApis.post(
                "/framework/api/getContentData",
                {
                    search: inputValue,
                    page: "1",
                    limit: "100"
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                  if (response?.data?.data?.length > 0) {
                    const fetchedOptions = response?.data?.data?.map(option => ({
                        value: option.cfile,
                        label: option.title
                    }));
                    setContentOptions(fetchedOptions);
                  } else {
                    setContentOptions([]);
                  }
                }
            }
        } catch (err) {
            setContentOptions([]);
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
  }
  
  const onSelectFromEmail = (selectedList) => {
    setSelectedFromEmail(selectedList);
  };
  
  const onSelectReplyEmail = (selectedList) => {
    setSelectedReplyEmail(selectedList);
  };
  
  const onSelectContactList = (selectedList) => {
    setSelectedPushList(selectedList);
  };
  
  const onSelectContact = (selected) => {
    setSelectedContact(selected);
  };
  
  const onRemoveContact = (selectedList) => {
    setSelectedContact(selectedList);
  };
  
  const onSelectPartner = (selected) => {
    setSelectedPartnerCategory(selected);
    setSelectedPushList(contact_list[0]);
  };
  
  const onRemovePartner = (selectedList) => {
    setSelectedPartnerCategory(selectedList);
  };
  
  const addHashTag = () => {
    if (tagName !== "") {
    //   dispatch(addNewHashTag(authToken, tagName));
      setTagName("");
      setTagModal(false);
    } else {
      Toast("Please enter tag name", "E");
    }
  }
  
  const handleAssetDetailData = () => {
    window.scrollTo(0, 0);
    let emailData = {
      thumb: imagefile,
      id:autoEmailContentId || null,
      temp_name: templateName,
      temp_desc: templateDescription,
      greeting: selectedGreeting,
      category: selectedCategory.map(obj=>obj.id),
      hashtag: selectedHashtag.map(obj=>obj.id),
      campaign_type: currentSipChoice,
      fund_name: selectedFundName,
      cta_type: selectedCtaType,
      landing_page: selectedLandingPage?.publish_page_id,
      showcase_page: selectedShowcasePage?.id,
      email_body: emailBody
    }
    
    // dispatch(autoEmailMainData(emailData))
    console.log(emailData);
    // dispatch(setBreadcrumbStatus(4));
  }
  
  const handleHtmlUpdate = () => {
    setTabData("details");
    setHtmlContent(emailBody);
    window.scroll(0, 0);
  }
  
  const customStyles = {
  option: (provided, state) => ({
    ...provided,
      backgroundColor: state.isSelected ? '#FFF' : '#FFF', // Set the background color to white for selected and unselected options
      color: state.isSelected ? '#333' : '#333', // Set the text color
      '&:hover': {
        backgroundColor: '#F0F0F0', // Set the background color on hover
      },
    }),
  };
  
  const handleCampaignAction = () => {
    if (props.subPageName !== "push_campaign") {
      handleRetainRunCampaignData();
    } else {
      handleRetainPushCampaignData();
    }
  }
  
  const handleRetainPushCampaignData = () => {
    let push_camp_data = {
      action: "auto_update_campaign",
      message: emailBody,
      camp_name: campaignName,
      subject: templateDescription,
      category_ids: selectedPartnerCategory?.map((partner)=>partner?.id),
      list_id: selectedPushList?.list_id,
      camp_id: individual_template?.mail_id,
      push_notification: pushNotification === true ? 1 : 0,
      scheduled_email: date != "" ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null,
    }
    dispatch(pushEmailCampaign(authToken, push_camp_data, onSuccess));
  }
  
  const handleRetainRunCampaignData = () => {
    let run_camp_data = {
      action: "update_campaign",
      message: emailBody,
      subject: templateDescription,
      camp_name: campaignName,
      attachment_option: attachContent != false ? 1 : 0, // 0,1,
      select_content: selectedAttachContent?.value,
      camp_id: individual_template?.mail_id,
      from_name: campaignFromName,
      from_mail: selectedFromEmail?.value,
      reply_email: selectedReplyEmail?.value,
      permission_reminder: permissionRem != false ? 1 : 0, //0,1,
      reminder_text: permissionRem != false ? campaignDescription:"",
      list_id: selectedContact.map((list)=>list.list_id),
      scheduled_email: date != "" ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null,
      greeting_id: selectedGreeting,
      test: null
    }
    dispatch(runTestEmailCampaign(authToken, run_camp_data, onSuccess, "run"));
  }
  
  const onSuccess = (path) => {
    if (props.subPageName !== "push_campaign") {
      if (path == "run") {
        navigate("/campaigns")
      } else {
        setTestModal(false);
      }
    } else {
      navigate("/campaigns/indirect")
    }
  }
  
  const handleRunTestEmail = () => {
    let run_camp_data = {
      action: "update_campaign",
      message: emailBody,
      subject: templateDescription,
      camp_name: campaignName,
      attachment_option: attachContent != false ? 1 : 0, // 0,1,
      select_content: selectedAttachContent?.value,
      camp_id: individual_template?.mail_id,
      from_name: campaignFromName,
      from_mail: selectedFromEmail?.value,
      reply_email: selectedReplyEmail?.value,
      permission_reminder: permissionRem != false ? 1 : 0, //0,1,
      reminder_text: permissionRem != false ? campaignDescription:"",
      list_id: selectedContact.map((list)=>list.list_id),
      scheduled_email: moment(date).format('YYYY-MM-DD HH:mm:ss') || "",
      greeting_id: selectedGreeting,
      test: testEmail
    }
    dispatch(runTestEmailCampaign(authToken, run_camp_data, onSuccess, "test"));
  }

  const updateButtonPosition = () => {
    // alert("enter hit");
    const editor = editorRef.current;
    if (editor) {
      const contentAreaContainer = editor.getContentAreaContainer();
      const editorIframe = contentAreaContainer.querySelector('iframe');
      const iframeWindow = editorIframe.contentWindow;
      const iframeDocument = iframeWindow.document;
      const selection = iframeDocument.getSelection();

      if (selection.rangeCount > 0) {
        const cursorPosition = selection.getRangeAt(0).getBoundingClientRect();
        const iframeRect = editorIframe.getBoundingClientRect();
        setButtonPos({
          left: (cursorPosition.left || -1000) + 50,
          top: cursorPosition.top + 263,
          show: true,
        });
      }
    }
  };

  useEffect(() => {
    const handleIframeLoad = () => {
      const editorInstance = editorRef.current;
      if (editorInstance && tabData !== "details") {
        const iframe = editorInstance?.getContentAreaContainer().querySelector('iframe');
        const iframeWindow = iframe.contentWindow;
        const mouseUpHandler = () => {
          updateButtonPosition();
          setIsButtonVisible(true);
        };
        iframeWindow.addEventListener('keyup', updateButtonPosition);
        iframeWindow.addEventListener('mouseup', mouseUpHandler);
        iframeWindow.addEventListener('scroll', handleScroll);

        return () => {
          iframeWindow.removeEventListener('keyup', updateButtonPosition);
          iframeWindow.removeEventListener('mouseup', mouseUpHandler);
          iframeWindow.addEventListener('scroll', handleScroll);
        };
      }
    };

    const editorInstance = editorRef?.current;
    if (editorInstance && tabData !== "details") {
      const contentAreaContainer = editorInstance?.getContentAreaContainer();
      const iframe = contentAreaContainer.querySelector('iframe');

      if (iframe) {
        iframe.addEventListener('load', handleIframeLoad);
      }

      return () => {
        if (iframe) {
          iframe.removeEventListener('load', handleIframeLoad);
        }
      };
    }
  }, [editorRef.current]);
  
  const handleCalendarOpen = () => {
    const today = new Date();
    if (!date) {
      handleDateChange(today);
    }
  };
  
  const handleDirectCalendarOpen = () => {
    const today = new Date();
    if (!date) {
      handleDirectDateChange(today);
    }
  };
  
  const insertTextAtCursor = (value) => {
    const editor = editorRef.current;
    if (editor) {
      editor.execCommand('mceInsertContent', false, value);
      let b_pos = buttonPos;
      b_pos.show = false;      
      setButtonPos(b_pos)
    }
  };
  
  const handleClosePopup = (value) => {
    setTagModal(value);
    setListPopup(value);
  }
  
  const onSuccessList = () => {
      setTagName("");
      setTagModal(false);
      setListPopup(true);
    }  
  
  return (
    <div className="auto-email email-campaign">
      <div className="new-asset">
        {isLoading && <Loading />}
        <Card className="mx-2 border border-0">
          <Row className="email-container w-100 g-0 p-0">
            <Col className={`email-fields-card design_padding ${tabData != "upload" ? "col-lg-6" : "col-lg-12"} col-md-12`}>
              <div className="camp-head">
                {props.topTitle}
              </div>
              <nav class="d-flex gap-3 justify-content-left mb-3">
                <span onClick={() => {
                    setTabData("details");
                    // dispatch(setBreadcrumbStatus(3));
                }}
                    class={`text-dark cursor-pointer fw-medium py-1 px-1 ${tabData === "details" && "active-nav"}`}
                >
                  Details
                </span>
                {tabData === "upload" && <span class={`text-dark cursor-pointer fw-medium py-1 px-1 ${tabData === "upload" && "active-nav"}`}>
                  Edit Email Template
                </span>}
              </nav>
              {tabData === "details" && <>
                
              <Form.Group className="mb-3 smartFolder-multiSelect">
                <Form.Control
                  className="d-flex input-fix-height align-items-center gap-3 border rounded bg-white "
                  type="text"
                  autoComplete="off"
                  required
                  placeholder="Campaign Name"
                  value={campaignName}  
                  onChange={(e) => {
                    setCampaignName(e.target.value)
                  }}
                />
              </Form.Group>
              {props.subPageName !== "push_campaign" && <>
                <Form.Group className="mb-3 smartFolder-multiSelect">
                  <Form.Control
                    className="d-flex input-fix-height align-items-center gap-3 border rounded bg-white "
                    type="text"
                    autoComplete="off"
                    required
                    placeholder="From Name"
                    value={campaignFromName}  
                    onChange={(e) => {
                      setCampaignFromName(e.target.value)
                    }}
                  />
                </Form.Group>

                <div className="w-100 mb-3 hash-email">
                  <div className="input-form-column">
                    <Select styles={customStyles} placeholder="From Email" options={fromEmail} isSearchable value={selectedFromEmail} onChange={onSelectFromEmail} getOptionLabel={getEmailOptionLabel} />    
                  </div>
                </div>
                  
                <div className="w-100 mb-3 hash-email">
                  <div className="input-form-column">
                    <Select styles={customStyles} placeholder="Reply Email" options={replyEmail} isSearchable value={selectedReplyEmail} onChange={onSelectReplyEmail} getOptionLabel={getEmailOptionLabel} />    
                  </div>
                </div>  
                  
                <div key={`default-checkbox`} className="p-r mb-3">
                  <Form.Check
                    checked={permissionRem}
                    onChange={(e)=>setPermissionRem(e.target.checked)}                    
                    type="checkbox"
                    id={`default-checkbox`}
                    label={`Permission Reminder`}
                  />
                </div>  
                  
                {permissionRem === true && <FloatingLabel className="mb-3" controlId="floatingTextarea2" label="Campaign Description">
                  <Form.Control
                    as="textarea"
                    value={campaignDescription}
                    onChange={(e)=>{setCampaignDescription(e.target.value)}}                    
                    placeholder="Campaign Description"
                    style={{ height: '100px' }}
                  />
                </FloatingLabel>}

                <div className="w-100 mb-3 hash-email">
                    {contact_list && <div className="input-form-column w-100">
                      <Multiselect
                        className="input-form w-100"
                        options={filtered_contact_list || []}
                        avoidHighlightFirstOption={true}
                        selectedValues={selectedContact}
                        onSelect={onSelectContact}
                        onRemove={onRemoveContact}
                        displayValue="contact_list_name"
                        showCheckbox={true}
                        placeholder="Select Contact List"
                      />
                    </div>}
                  {/* <div className="input-form-column">
                    <Select styles={customStyles} placeholder="Select Contact List" options={landingpageUrlList} isSearchable value={selectedLandingPage} onChange={onSelectLandingPage} getOptionLabel={getEmailOptionLabel} />    
                  </div> */}
                    {/* contact_list */}
                  <p
                    onClick={() => {
                      setListPopup(true);
                    }}
                    className="create-list"
                  >
                    Edit / Create List
                  </p>  
                </div> 
                </>
              }
              {props.subPageName === "push_campaign" &&<>  
              <div className="w-100 mb-3 hash-email">
                <div className="input-form-column w-100">
                  <Multiselect
                    className="input-form w-100"
                    options={partnersList}
                    avoidHighlightFirstOption={true}
                    selectedValues={selectedPartnerCategory}
                    onSelect={onSelectPartner}
                    onRemove={onRemovePartner}
                    displayValue="partner_category"
                    showCheckbox={true}
                    placeholderButtonLabel={selectedPartnerCategory?.length > 0 ? `${selectedPartnerCategory.length} selected` : "Select Partners"}
                    placeholder="Select Partner Category"
                  />
                </div>
              </div> 
                
              <div className="w-100 mb-3 hash-email">
                  <div className="input-form-column">
                    <Select styles={customStyles} placeholder="Select List" options={contact_list || []} isSearchable value={selectedPushList} onChange={onSelectContactList} getOptionLabel={getContactOptionLabel} />    
                  </div>
              </div>
              </>}  
              {props.subPageName === "push_campaign" ? <div className="w-100 mb-3 hash-email">  
                <DatePicker
                  className="d-flex input-fix-height align-items-center gap-3 border rounded bg-white"      
                  selected={date}
                  onChange={handleDateChange}
                  onCalendarOpen={handleCalendarOpen}  
                  minDate={now}
                  minTime={minSelectableTime}
                  maxTime={maxSelectableTime}
                  placeholderText={"Select Time"}
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeSelect
                />
                </div>
                  :
                <div className="w-100 mb-3 hash-email">  
                  <DatePicker
                    className="d-flex input-fix-height align-items-center gap-3 border rounded bg-white"      
                    selected={date}
                    onChange={handleDirectDateChange}
                    onCalendarOpen={handleDirectCalendarOpen}  
                    minDate={now}
                    minTime={minSelectableDirectTime}
                    maxTime={maxSelectableTime}
                    placeholderText={"Select Time"}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeSelect
                  />
                </div>
              }
                
              {(props.subPageName !== "push_campaign" && userType != "C") && <>                
                <div className="smartFolder-multiSelect mb-3">
                  <div className="input-form-column w-100">
                    <Form.Select
                      className="d-flex align-items-center input-fix-height gap-3 border rounded bg-white "
                      aria-label="select-greetings"
                      style={{ color: "#6e6b6b" }}
                      onChange={(e) => {
                        setSelectedGreeting(e.target.value);
                      }}
                      value={selectedGreeting}
                    >
                      <option value="">Select Greeting</option>
                      {greetingList?.length > 0 && greetingList?.map((greet,g_ind)=>{
                      return (
                        <option key={greet.greetingID} value={greet.greetingID}> {greet.greeting_name} </option>
                      )
                    })}
                    </Form.Select>
                  </div>
                </div>  

                <div key={`default-checkbox-2`} className="p-r mb-3">
                  <Form.Check
                    checked={attachContent}
                    onChange={(e)=>{setAttachContent(e.target.checked)}}                    
                    type="checkbox"
                    id={`default-checkbox-2`}
                    label={`Attach Content`}
                    /> 
                </div>

                {attachContent === true && <div className="w-100 mb-3 hash-email">
                <div className="input-form-column">
                  <Select styles={customStyles}
                    placeholder="Select Content"
                    isClearable
                    isSearchable
                    isLoading={isLoading}
                    value={selectedAttachContent}     
                    onInputChange={handleInputChange}
                    onChange={(selectedList)=>{setSelectedAttachContent(selectedList)}}    
                    options={contentOptions}
                    // getOptionLabel={getEmailOptionLabel}
                  />    
                </div>
                </div>}
              </>}   
              
              {props.subPageName === "push_campaign" && <div key={`default-checkbox`} className="p-r mb-3">
                  <Form.Check
                    checked={pushNotification}
                    onChange={(e)=>setPushNotification(e.target.checked)}                    
                    type="checkbox"
                    id={`default-checkbox`}
                    label={`Push Notification`}
                  />
              </div>} 
                
                <Button
                  variant="info"
                  type="submit"
                  className="save-proceed-btn save-proceed-btn-run mt-lg-0 mt-3 mx-2"
                  // disabled={submitBtn}
                  onClick={()=>{handleCampaignAction()}}
                >
                  Run
                  <span>
                    <Icon.ChevronRight />
                  </span>
                </Button> 
                {props.subPageName !== "push_campaign" && <Button
                  variant="info"
                  type="submit"
                  className="save-proceed-btn save-proceed-btn-run save-color mt-lg-0 mt-3"
                  // disabled={submitBtn}
                  onClick={() => {
                    setTestModal(true);
                  }}
                >
                  Test
                  <span>
                    <Icon.ChevronRight />
                  </span>
                </Button> }             
              </>}
              {tabData === "upload" && <>
                <Form.Group className="mb-3 smartFolder-multiSelect w-50">
                  <Form.Control
                    className="d-flex input-fix-height align-items-center gap-3 border rounded bg-white "
                    type="text"
                    autoComplete="off"
                    required
                    placeholder="Email Subject"
                    value={templateDescription}  
                    onChange={(e) => {
                      setTemplateDescription(e.target.value)
                    }}
                  />
                </Form.Group>
                <div className="positon-relative">
                <Editor
                  className="d-flex input-fix-height align-items-center gap-3 border rounded bg-white form-control"
                  apiKey='dlxobbzpbqb3bqp8b9bkjwwcaru35qqdwpihxqk9o4nklx3d'
                  onInit={(_evt, editor) => editorRef.current = editor}
                  onChange={(e) => { setEmailBody(e.level.content) }}
                  init={{
                    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker code',
                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link code table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                    tinycomments_mode: 'embedded',
                    menubar: 'false',
                    placeholder: "Enter email body",
                    selector: 'textarea',
                    height: 850,
                    statusbar: false,
                    branding: false,
                    tinycomments_author: 'Author name',
                    
                  }}
                  initialValue={htmlContent}
                  onKeyUp={updateButtonPosition}
                  onMouseUp={updateButtonPosition}
                />  
                {(isButtonVisible) && (
                  <Dropdown 
                    style={{
                      position: 'absolute',
                      left: buttonPos.left,
                      top: buttonPos.top,
                    }}
                    className="editor-button"
                  >
                    <Dropdown.Toggle variant="success" className="d-flex px-2 movable-add-button" id="dropdown-basic">
                      <Icon.Plus/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {personalize_variable?.map((item,index) => {
                          return (
                            <Dropdown.Item onClick={()=>{insertTextAtCursor(item.value)}}>{ item.key }</Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>    
                  // <button
                  //   className="editor-button"
                  //   style={{
                  //     position: 'absolute',
                  //     left: buttonPos.left,
                  //     top: buttonPos.top,
                  //   }}
                  // >
                  //   Button
                  // </button>
                )}
                </div>
                <Button
                  variant="info"
                  type="submit"
                  className="save-proceed-btn btn-pos mt-lg-0"
                  onClick={handleHtmlUpdate}
                >
                  Update
                  <span>
                    <Icon.ChevronRight />
                  </span>
                </Button>
              </>}
            </Col>
            {(tabData != "upload")&& <Col className="email-fields-card design_padding preview-mode bg-text-white col-lg-6 col-md-12">
              <p className="text-disabled d-flex justify-content-between align-items-center font-inter font-size-16 font-weight-400 lh-24 custom-font-features">
                {autoemailStatus == 1 && (htmlContent == "") ? "No Preview Available":"Preview"}
                {userType != "C" && <Button
                  variant="info"
                  type="submit"
                  className="save-proceed-btn edit-proceed-btn-run mt-lg-0 mt-3 mx-2"
                  // disabled={submitBtn}
                  onClick={() => { setTabData("upload"); handleAssetDetailData()}}
                >
                  Edit Email or Subject
                </Button> }
              </p>
              <p className="my-3 subject-preview">{templateDescription}</p>
                <div
                  className="d-flex custom-style flex-column align-items-start p-4 gap-3 rounded bg-white"
                  style={{ width: "529px" }}
                >
                <div dangerouslySetInnerHTML={{ __html: htmlContent }} style={{ zoom: 0.6,margin:"auto"}} className="d-flex flex-column justify-content-end align-items-stretch">
                  </div>
                </div>
            </Col>}
          </Row>
        </Card>
      </div>
      
      <Modal
        size="xl"
        show={listPopup}
        onHide={() => setListPopup(true)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="listing-container"
      >
        <Modal.Header className="header-color">
          <Modal.Title id="example-modal-sizes-title-lg" className="w-100 d-flex justify-content-between">
            <span>{currentPopupList === false ? "Contact List" : "Contacts"}</span>
            <div className="col-5 position-relative">
                <DebounceInput
                  value={searchField}
                  className="search-field-list form-control"
                  placeholder={currentPopupList === false ? "Search contact list" : "Search contacts"}
                  minLength={3}
                  debounceTimeout={1500}
                  onChange={(e) => {
                    setSearchField(e.target.value);
                    dispatch({ type: types.CAMPAIGN_SEARCH_INPUT, payload: e.target.value });
                  }}
                />
                { searchField === "" ? (
                    <div className="search-icon-list">
                      <Icon.Search
                        // onClick={(e) => {
                        //   // searchSubmitHandler(e, searchRef.current.value, pagename);
                        // }}
                      />
                    </div>
                  ) : (
                    <div className="search-icon-list">
                      <Icon.X
                        onClick={(e) => {
                          setSearchField("");
                          dispatch({ type: types.CAMPAIGN_SEARCH_INPUT, payload: "" });
                        }}
                      />
                    </div>
                  )}
            </div>
            {currentPopupList === false ? <Button
              variant="info"
              className="add-new-asset-btn me-2"
              onClick={() => {
                setListPopup(false)
                setTagModal(true);
                dispatch({ type: types.CAMPAIGN_SEARCH_INPUT, payload: "" });
              }}
            >
              <span className="crm-header-btn-icon">
                <Icon.PlusLg />
              </span>
              Create List
            </Button>
              :
            <>
            <Button
              variant="info"
              className="add-new-asset-btn me-2"
              onClick={() => {
                handleModalValue(false);
                setSearchField("")
                dispatch({ type: types.CAMPAIGN_SEARCH_INPUT, payload: "" });
                dispatch(fetchListDataListing(authToken, "", 1, "", ""));
              }}
            >
              <span className="crm-header-btn-icon">
                <Icon.ChevronLeft />
              </span>
              Back
            </Button>
          </>  
          }
          </Modal.Title>
          <button onClick={() => { setListPopup(false);  setSearchField("")}} type="button" class="btn-close" aria-label="Close"></button>
        </Modal.Header>
        <Modal.Body className="list-popup listing">
          <ListModal authToken={props.authToken} currentPopupList={currentPopupList} searchField={searchField} handleModalValue={handleModalValue} sortValue={sortValue} pageName={props.pageName} subPageName={"list_modal"} handleClosePopup={handleClosePopup} innerTitle={props.innerTitle}/>
        </Modal.Body>
      </Modal>
      
      <Modal centered show={tagModal} onHide={() => { setTagModal(false); setListPopup(true);  setTagName("");}}>
        <Modal.Header closeButton>
          <div className="fw-bold h5">Create New List</div>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <Form.Control
              type="text"
              autoComplete="off"
              name="tag-input"
              required
              placeholder="List Name"
              value={tagName}
              onChange={(e) => setTagName(e.target.value)}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          {" "}
          {/* style={{ border: "none" }} */}
          <Button
            variant="info"
            onClick={() => {
              dispatch(addContactList(authToken, tagName, onSuccessList))
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      
      {/* test email  */}
      <Modal show={testModal} onHide={() => { setTestModal(false);  setTestEmail("");}}>
        <Modal.Header closeButton>
          {" "}
          {/* style={{ border: "none" }} */}
          <div className="fw-semibold h5">Testing Email</div>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <Form.Control
              type="text"
              autoComplete="off"
              name="tag-input"
              required
              placeholder="Enter test email ids comma separated"
              value={testEmail}
              onChange={(e) => setTestEmail(e.target.value)}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          {" "}
          <Button
            variant="info"
            type="submit"
            className="save-proceed-btn save-proceed-btn-run w-auto d-flex gap-3 align-items-center save-color mt-lg-0 mt-0"
            // disabled={submitBtn}
            onClick={() => {
              if (testEmail !== "") {
                handleRunTestEmail()
              } else {
                Toast("Please enter test email with comma separated", "E");
              }
              
            }}
          >
            Send Test Email
            <span>
              <Icon.ChevronRight />
            </span>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EmailCampaign;